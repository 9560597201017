import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["element", "input"];

  connect() {
    if (!this.hasElementTarget || !this.hasInputTarget) {
      return;
    }

    this.inputTarget.dataset.originalType = this.inputTarget.type;
    this.inputTarget.type = "hidden";
    this.elementTarget.setAttribute("contenteditable", true);
    this.elementTarget.dataset.placeholder = this.inputTarget.placeholder;
    this.elementTarget.innerText = this.inputTarget.value;
    if (this.elementTarget.autofocus) {
      window.setTimeout(() => {
        this.elementTarget.dispatchEvent(new Event("click"));
      }, 100);
    }

    this.elementTarget.addEventListener("input", this.updateInput.bind(this));
    this.updateInput(null, false);
  }

  disconnect() {
    this.elementTarget.removeAttribute("contenteditable");
    this.elementTarget.innerText = "";
    this.inputTarget.type = this.inputTarget.dataset.originalType;

    this.inputTarget.removeEventListener("input", this.setInputValue);
  }

  updateInput(evt, cascadeEvent = true) {
    if (this.elementIsEmpty) {
      this.elementTarget.innerText = "";
      this.inputTarget.value = "";
    }
    this.inputTarget.value = this.elementTarget.innerText;
    if (cascadeEvent) {
      this.inputTarget.dispatchEvent(new Event("input"));
    }
  }

  get placeholderText() {
    return this.inputTarget.placeholder;
  }

  get placeholderIsVisible() {
    return this.placeholderText === this.elementTarget.innerText;
  }

  get elementIsEmpty() {
    return this.elementTarget.innerText.trim() === "";
  }
}
